<template>
  <div id="app">
    <dv-full-screen-container>
      <router-view/>
    </dv-full-screen-container>
  </div>
</template>
<script>
export default {
  components:{
  },
  data(){
    return{
      debug:false
    }
  },
  created() {
    this.debug = process.env.NODE_ENV === "development"
    // 屏幕分辨率更改时刷新页面
    window.onresize = () => {
      // if (document.body.clientWidth < 1920) {
        location.reload()
      setTimeout(() => {
        location.reload()
      }, 2000)
      // }
    }
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  //border:1px solid red;
  box-sizing: border-box;
  background-image: url('@/assets/images/bg.png');
  background-repeat: no-repeat;
  background-color: #000;
  background-position: top center;
  background-size: cover;
  height: 100vh;
}
//注册字体 Milky-Han-Term-CN-Bold
@font-face {
  font-family: 'Milky-Han-Term-CN-Bold';
  src: url('./assets/fonts/Milky Han Term CN Bold.ttf') format('truetype'),
  url('./assets/fonts/Milky Han Term CN Bold.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
</style>
