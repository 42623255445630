import {
    fullScreenContainer,
    decoration5,
    borderBox8
} from '@jiaminghi/data-view'
import Vue from "vue";

Vue.use(fullScreenContainer)
    .use(decoration5)
    .use(borderBox8)
