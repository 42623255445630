/*
 * @Date: 2024-03-06 15:00:08
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-08 10:03:58
 * @FilePath: \zj-dataview\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/common/font/font.scss'
import 'vant/lib/index.css'
import '@/plugins/datav'
import '@/util/rem'
import axios from 'axios'
axios.defaults.baseURL= process.env.NODE_ENV=== 'development'? '/' : 'https://dv.zhijiangfilm.com/api/'
// 开发环境
// axios.defaults.baseURL = 
console.log(process.env);

axios.defaults.timeout = 10000
import dataV from '@jiaminghi/data-view'
import 'echarts-gl'

Vue.use(dataV)

Vue.prototype.$axios = axios
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
