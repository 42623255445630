import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue')
  },
  {
    path: '/Meet',
    name: 'Meet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/meetRoom.vue')
  },
  {
    path: '/party',
    name: 'party',
    component: () => import(/* webpackChunkName: "about" */ '../views/partyView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
